<template>
    <div class="bg-black py-4 flex">
        <div class="text-white sm:mx-20 mx-5 md:w-1/3 w-2/3 flex gap-5">
            <div>
                <div>ОГРН: 320650100016610</div>
                <div>ИНН: 650113740692</div>
            </div>
            <div>
                <div>
                    ИП Морозова Н.В.
                </div>
                <div>
                    8(924)-288-47-93
                </div>
            </div>
        </div>
        <div class="text-white flex items-center">
            Все права защищены
        </div>
    </div>
</template>

<script setup>

</script>