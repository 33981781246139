<template>
    <div class="max-w-[1200px] mx-auto relative w-fit">
        <h1 class="sm:text-4xl text-2xl pl-20 marked font-bold mt-20">Цены</h1>
    </div>
    <div class="max-w-[1200px] mt-10 flex justify-around items-center mx-auto">
        <div class="md:w-3/5 w-[90%] mx-auto">
            <div class="text-lg flex pb-5 mt-5">
                <div class="w-2/5 flex gap-2 mr-2">
                    <svg class="h-8 w-8 text-black"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="1" y="3" width="15" height="13" />  <polygon points="16 8 20 8 23 11 23 16 16 16 16 8" />  <circle cx="5.5" cy="18.5" r="2.5" />  <circle cx="18.5" cy="18.5" r="2.5" /></svg>
                    Микроавтобус:</div>
                <div class="flex flex-col w-2/3">
                    <div class="flex sm:gap-10 sm:justify-start justify-between"><div class="w-1/5 border-b border-black pb-5 mr-5">Город</div> <span class="w-1/3">1200p</span></div>
                    <div class="flex items-center sm:gap-10 sm:justify-start justify-between"><div class="w-1/5 border-b border-black pb-5 mr-5 pt-5">Анива, Долинск, Корсаков, Охотское </div> <span class="w-1/3">4000р</span></div>
                    <div class="flex items-center sm:gap-10 sm:justify-start justify-between"><div class="w-1/5 border-b border-black pb-5 mr-5 pt-5">Невельск, Холмск,  </div> <span class="w-1/3">6500р</span></div>
                    <div class="flex items-center sm:gap-10 sm:justify-start justify-between"><div class="w-1/5 border-b border-black pb-5 mr-5 pt-5">Взморье  </div> <span class="w-1/3">9000</span></div>
                    <div class="flex items-center sm:gap-10 sm:justify-start justify-between"><div class="w-1/5 border-b border-black pb-5 mr-5 pt-5">Гостелло  </div> <span class="w-1/3">12000</span></div>
                    <div class="flex items-center sm:gap-10 sm:justify-start justify-between"><div class="w-1/5 border-b border-black pb-5 mr-5 pt-5">Межгород   </div> <span class="w-1/3">50р/1км</span></div>
                </div>
            </div>


            <div class="text-lg flex pb-5 mt-5">
                <div class="w-2/5 flex gap-2 mr-2">
                    <svg class="h-8 w-8 text-black"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="1" y="3" width="15" height="13" />  <polygon points="16 8 20 8 23 11 23 16 16 16 16 8" />  <circle cx="5.5" cy="18.5" r="2.5" />  <circle cx="18.5" cy="18.5" r="2.5" /></svg>
                    Грузовик:</div>
                <div class="flex flex-col w-2/3">
                    <div class="flex sm:gap-10 sm:justify-start justify-between"><div class="w-1/5 border-b border-black pb-5 mr-5">Город</div> <span class="w-1/3">2000p</span></div>
                    <div class="flex items-center sm:gap-10 sm:justify-start justify-between"><div class="w-1/5 border-b border-black pb-5 mr-5 pt-5">Анива, Долинск, Корсаков, Охотское </div> <span class="w-1/3">6000р</span></div>
                    <div class="flex items-center sm:gap-10 sm:justify-start justify-between"><div class="w-1/5 border-b border-black pb-5 mr-5 pt-5">Невельск, Холмск,  </div> <span class="w-1/3">10000р</span></div>
                    <div class="flex items-center sm:gap-10 sm:justify-start justify-between"><div class="w-1/5 border-b border-black pb-5 mr-5 pt-5">Межгород   </div> <span class="w-1/3">100р/1км</span></div>
                </div>
            </div>


            
        </div>
        <div class="xl:w-2/5 w-2/6 md:block hidden">
            <img src="@/assets/boxes.png" alt="boxes">
        </div>
    </div>

    <div class="flex gap-1 items-center my-4 mx-auto w-full justify-center sm:mt-10 sm:mb-10 mt-10 mb-10">
        <div class="sm:text-3xl text-2xl ml-2 text-center font-light">Готовность к принятию поэтапных и долгосрочных заказов под "ключ"</div>
    </div>
</template>

<script setup>

</script>
