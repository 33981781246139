<template>
    <div class="mx-auto">
        <div class="max-w-[1200px] mx-auto relative w-fit ">
            <h1 class="sm:text-4xl text-2xl pl-20 marked font-bold sm:mt-20 mt-10">Наша компания</h1>
        </div>
        <div class="flex mx-auto max-w-[1200px] items-center sm:pb-20 pb-5">
            <div class="lg:w-2/3 ml-10 sm:mr-0 mr-10 sm:w-2/4 mt-16 w-full mx-auto">
                <div class="text-center text-[#ef9000] text-2xl mb-4">Наши специализации:</div>
                <div class="w-fit mx-auto flex flex-wrap">
                    <div class="flex gap-1 items-center w-1/2 my-4">
                        <div class="text-[#ef9000] text-2xl">01</div>
                        <div class="lg:text-lg text-base ml-2">Квартирные переезды</div>
                    </div>
                    <div class="flex gap-1 items-center w-1/2 my-4">
                        <div class="text-[#ef9000] text-2xl">02</div>
                        <div class="lg:text-lg text-base ml-2">Доставка бытовой техники</div>
                    </div>
                    <div class="flex gap-1 items-center w-1/2 my-4">
                        <div class="text-[#ef9000] text-2xl">03</div>
                        <div class="lg:text-lg text-base ml-2">Доставка мебели</div>
                    </div>
                    <div class="flex gap-1 items-center w-1/2 my-4">
                        <div class="text-[#ef9000] text-2xl">04</div>
                        <div class="lg:text-lg text-base ml-2">Доставка строй материалов</div>
                    </div>
                    <div class="flex gap-1 items-center w-1/2 my-4">
                        <div class="text-[#ef9000] text-2xl">05</div>
                        <div class="lg:text-lg text-base ml-2">Грузчики</div>
                    </div>
                </div>
            </div>
            <div class="float-right xl:w-2/3 xl:max-w-full sm:w-2/4 sm:block hidden">
                <img src="../assets/car.png" alt="car" class="object-cover mx-auto w-full">
            </div>
        </div>
        <div class="flex items-center justify-center w-full pb-10 mt-10">
            <button class="bg-red-600 text-white px-6 py-2.5 rounded text-2xl mx-auto hover:opacity-80" @click="emitHandler">Связаться с нами</button>
        </div>
    </div>

</template>

<script>
export default {
    data(){
        return {

        }
    }, 
    methods: {
        emitHandler(){
            this.$emit('showModal')
        }
    }
}
</script>