<template>
    <div class="mx-auto pb-20">
        <div class="max-w-[1200px] mx-auto relative w-fit">
            <h1 class="sm:text-4xl text-2xl pl-20 marked font-bold sm:mt-40 mt-20">Связь с нами</h1>
        </div>

        <div class="max-w-[1200px] mx-auto flex flex-col mt-10 sm:w-full w-fit">
            <div class="flex flex-col sm:text-xl text-base gap-6 mx-auto sm:w-[80%]">
                <div class="flex gap-5 items-center">
                    <div class="w-2/6 border-b">Адрес электронной почты:</div> 
                    <span class="w-fit">Natalya.natalya.morozova@mail.ru</span>
                </div>
                <div class="flex gap-5 items-center">
                    <div class="w-2/6 border-b">Телефон:</div> 
                    <span class="w-fit"><a href="tel:+7(924)1817537">+7(924)181-75-37</a></span>
                </div>
                <div class="flex gap-5 items-center">
                    <div class="w-2/6 border-b">Наш адрес:</div> 
                    <span class="w-fit">г. Южно-Сахалинск, Херсонская 18-5</span>
                </div>

            </div>
        </div>

    </div>

</template>

<script setup>

</script>