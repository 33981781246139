<template>
  <div id="gallery" class="zoomwall w-full max-w-[1200px] mx-auto mb-20">
    <div class="max-w-[1200px] mx-auto relative w-fit">
            <h1 class="sm:text-4xl text-2xl pl-20 marked font-bold sm:mt-20 sm:mb-10 mt-10 mb-10">Галлерея</h1>
    </div>
    <img src="../Gallery/picture/1.jpg" style="display: inline" class="w-1/4 p-1 min-h-[300px] object-cover">
    <img src="../Gallery/picture/2.jpg" style="display: inline" class="w-1/4 p-1 min-h-[300px] object-cover">
    <img src="../Gallery/picture/3.jpg" style="display: inline" class="w-1/4 p-1 min-h-[300px] object-cover">
    <img src="../Gallery/picture/4.jpg" style="display: inline" class="w-1/4 p-1 min-h-[300px] object-cover">
    <img src="../Gallery/picture/5.jpg" style="display: inline" class="w-1/4 p-1 min-h-[300px] object-cover">
    <img src="../Gallery/picture/6.jpg" style="display: inline" class="w-1/4 p-1 min-h-[300px] object-cover">
    <img src="../Gallery/picture/7.jpg" style="display: inline" class="w-1/4 p-1 min-h-[300px] object-cover">
    <img src="../Gallery/picture/8.jpg" style="display: inline" class="w-1/4 p-1 min-h-[300px] object-cover">

  </div>
</template>
<script setup>
import * as zoomwall from 'zoomwall.js/lib/zoomwall.js'

window.onload = function() {
  zoomwall.create(document.getElementById("gallery"));
  console.log('create')
};


</script>

<style>
.zoomwall {
  font-size: 0;
  overflow: hidden;
}

.zoomwall img {
  max-height: 300px !important;
  opacity: 1;
  vertical-align: top;
  transform-origin: 0% 0%;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.zoomwall.lightbox img {
  opacity: 0.3;
  transition-timing-function: ease-in;
}

.zoomwall.lightbox img.active {
  opacity: 1;
}
</style>