<template>
    <div class="flex items-center sm:justify-between lg:w-7/12 w-full mx-auto container pt-2 justify-around" id="price">
        <div class="flex sm:text-lg text-sm items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            </svg>
            Южно-Сахалинск, Херсонская 18-5
        </div>
        <div>
            <div class="flex items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                </svg>
                <div class="sm:text-lg text-sm">
                    <a href="tel:303-440">303-440</a>
                </div>
            </div>
            <div class="sm:text-lg text-sm sm:block hidden">
                Есть вопросы? Звоните 24/7
            </div>
        </div>
    </div>

    <div class="lg:w-8/12 w-full overflow-hidden overflow-x-hidden mx-auto bg-primary px-8 bg-[#3385bee6] transform -skew-x-12 opacity-90 mt-2">
        <div class="flex flex-col items-center justify-around text-white font-bold skew-x-12">
            <div class="sm:text-3xl text-2xl font-normal pt-5">
                Компания "Комфорт плюс"
            </div>
            <div class="font-light sm:text-lg text-sm pb-5">
                Грузоперевозки по Сахалинской области
            </div>
        </div>
    </div>
</template>