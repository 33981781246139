<template>
    <div class="max-w-[800px] mx-auto sm:mb-40 mb-20">
        <div class="max-w-[1200px] mx-auto relative w-fit mb-20">
            <h1 class="text-4xl pl-20 marked font-bold sm:mt-40 mt-20">Отзывы о нас</h1>
        </div>
        <div class="flex gap-5 mt-10">
            <div class="rounded w-fit min-w-[200px] overflow-hidden h-[200px] relative">
                <img src="./picture/1.jpg" alt="people" class="object-cover absolute object-center rounded">
            </div>
            <div class="flex flex-col">
                <div class="font-bold text-xl">Ирина</div>
                <div>Профессиональный подход ко всем деталям в работе. Добросовестная организация, всегда на связи. Отличный перевозчик, зарекомендовал себя с хорошей стороны. Работа выполнена на высшем уровне</div>
            </div>
        </div>

        <div class="flex gap-5 mt-10">
            <div class="rounded w-fit min-w-[200px] overflow-hidden h-[200px] relative">
                <img src="./picture/2.jpg" alt="people" class="object-cover absolute object-center rounded w-[200px]">
            </div>
            <div class="flex flex-col">
                <div class="font-bold text-xl">Ефим</div>
                <div>Отличная компания! Всем рекомендую, загрузка, выгрузка на высоком уровне, слаженный коллектив, ответственный подход, вежливые ребята.</div>
            </div>
        </div>

        <div class="flex gap-5 mt-10">
            <div class="rounded w-fit min-w-[200px] overflow-hidden h-[200px] relative">
                <img src="./picture/3.jpg" alt="people" class="object-cover absolute object-center rounded w-[200px]">
            </div>
            <div class="flex flex-col">
                <div class="font-bold text-xl">Ирина Грызлова</div>
                <div>Заключили договор с данной компанией, услуги были выполнены в соответствии с договором, планируем сотрудничать дальше. Отличный перевозчик.</div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>