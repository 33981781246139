<template>
    <teleport to='body'>
        <div class="mx-auto flex flex-col absolute top-0 w-screen h-screen modal-black" v-if="props.showModal" @click="closeHandler">
            <div class="flex flex-col sm:text-xl text-base gap-6 w-fit mt-80 mx-auto bg-white rounded p-5 relative" @click.stop>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 absolute right-5 cursor-pointer" @click="closeHandler">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

                <div class="flex gap-5 items-center">
                    <div class="w-2/6 border-b">Адрес электронной почты:</div> 
                    <span>Natalya.natalya.morozova@mail.ru</span>
                </div>
                <div class="flex gap-4 items-center">
                    <div class="w-2/6 border-b">Телефон:</div> 
                    <span><a href="tel:303440">303 - 440</a></span>
                </div>
                <div class="flex gap-5 items-center">
                    <div class="w-2/6 border-b">WhatsApp:</div> 
                    <span><a href="tel:+79241817537">+7 (924)-181-75-37</a></span>
                </div>
                <div class="flex gap-5 items-center">
                    <div class="w-2/6 border-b">Telegramm:</div> 
                    <span><a href="tel:+79241817537">+7 (924)-181-75-37</a></span>
                </div>
                <div class="flex gap-5 items-center">
                    <div class="w-2/6 border-b">Наш адрес:</div> 
                    <span>г. Южно-Сахалинск, Херсонская 18-5</span>
            </div>
        </div>
    </div>
    </teleport>
</template>

<script setup>
import {defineProps, defineEmits} from 'vue';
const props = defineProps(['showModal'])
const emit = defineEmits(['showModal'])
const closeHandler = () => {
    emit('showModal')
}
</script>

<style>
.modal-black {
    background-color: rgba(0, 0, 0, 0.6);;
}
</style>