<template>
  <HeaderComponent />
  <CarComponent @showModal="showModalHandler" />
  <PriceComponent />
  <GalleryComponent />
  <ReviewComponent />
  <CallComponent />
  <FooterComponent />
  <ModalComponent :showModal="showModal" @showModal="showModalHandler" />
</template>

<script setup>
import {ref} from 'vue'
import HeaderComponent from './components/HeaderComponent.vue';
import CarComponent from './components/CarComponent.vue';
import PriceComponent from './components/PriceComponent.vue';
import CallComponent from './components/CallComponent.vue';
import GalleryComponent from './components/Gallery/GalleryComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import ReviewComponent from './components/Review/ReviewComponent.vue';
import ModalComponent from './components/ModalComponent.vue';

const showModal = ref(false);
const showModalHandler = () => {
  showModal.value = !showModal.value;
  if(showModal.value){
    document.body.classList.add('h-screen', 'overflow-hidden', 'pr-[15px]')
  } else {
    document.body.classList.remove('h-screen', 'overflow-hidden','pr-[15px]')
  }
}
</script>

